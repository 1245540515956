import React, {useEffect} from 'react'
import {
    Dropdown,
    MenuButton,
    Menu,
    MenuItem,
    Avatar,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    ListItemDecorator, ListItemContent, Card, CardContent, Button, Link
} from '@mui/joy'
import Swal from 'sweetalert2'
import {SidebarItems} from "../Sidebar";
import {ISidebarItems} from "../../types/sidebarItems";
import {Close, KeyboardArrowRight} from "@mui/icons-material";

import Logo from '../../assets/logo.svg'
import '../../styles/main.scss'
import '../../styles/events.scss'

export const CLayout = ({children}: {children: any}): React.ReactElement => {
    const [user] = React.useState({
        username: 'admin'
    })

    const [title] = React.useState('MainPage')

    const [innerWidth, setInnerWidth] = React.useState(window.innerWidth)

    const [openedSubmenu, setOpenedSubmenu] = React.useState<string>('')
    const handleOpenedSubmenu = (id: string) => {
        setOpenedSubmenu(openedSubmenu === id ? '' : id);
    }

    useEffect(() => {
        const handleResize = () => {
            const newWidth = window.innerWidth;
            setInnerWidth(newWidth);

            if (newWidth < 900) {
                Swal.fire({
                    title: "Écran trop petit !",
                    text: "Merci d'utiliser ce logiciel sur un ordinateur.",
                    icon: 'error',
                    showConfirmButton: false,
                    allowOutsideClick: () => {
                        return false
                    },
                })
            }
        }
        window.addEventListener('resize', handleResize);

        handleResize()

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            {innerWidth > 900 && (
                <section id={'layout'} className={'flex'}>
                    <aside className={'h-screen sticky top-0 z-[50]'}>
                        <div id={'sidebar'} className={'h-full fixed w-[20rem] p-6'}>
                            <a href={'/'}>
                                <h2 className={'flex items-center gap-4 text-xl font-medium text-default-900 mb-6'}>
                                    <img src={ Logo } width={30} height={30} alt={'logo_eeven'} />
                                    eevenPro
                                </h2>
                            </a>

                            <List className={'h-[80vh] overflow-y-auto'}>
                                {SidebarItems.map((item: ISidebarItems, key: number) => {
                                    return (
                                        <ListItem key={key} nested>
                                            <ListItem>
                                                <ListItemButton className={'!rounded-2xl'} selected={(window.location.pathname === item.url || openedSubmenu === item.id)} onClick={() => handleOpenedSubmenu(item.id)} component={'a'} href={item.url}>
                                                    <ListItemDecorator>{item.icon}</ListItemDecorator>
                                                    <ListItemContent>{item.title}</ListItemContent>
                                                    { item.submenu && (openedSubmenu === item.id ? <Close /> : <KeyboardArrowRight />) }
                                                </ListItemButton>
                                            </ListItem>
                                            {(item.submenu && openedSubmenu === item.id) && (
                                                <>
                                                    {item.subItems?.map((subItem: ISidebarItems, k: number) => (
                                                        <ListItem key={k}>
                                                            <ListItemButton className={'!rounded-2xl'} selected={window.location.href === item.url} component={'a'} href={subItem.url}>
                                                                <ListItemDecorator>{subItem.icon}</ListItemDecorator>
                                                                <ListItemContent>{subItem.title}</ListItemContent>
                                                            </ListItemButton>
                                                        </ListItem>
                                                    ))}
                                                </>
                                            )}
                                        </ListItem>
                                    )
                                })}
                            </List>

                            <div className={'absolute bottom-0 left-0 right-0 m-4'}>
                                <Card>
                                    <CardContent>
                                        <p>Besoin d'aide ?</p>
                                        <p className={'mb-8 text-gray-500'}>N'hésitez pas à nous contacter</p>

                                        <Button endDecorator={<KeyboardArrowRight />}>
                                            Prendre contact
                                        </Button>
                                    </CardContent>
                                </Card>
                            </div>
                        </div>
                    </aside>

                    <div className={'flex flex-col flex-1 ml-[20rem]'}>
                        <nav className={'py-3 mx-4 sticky flex items-center justify-between'}>
                            <p className={'text-xl'}>{title}</p>
                            <Dropdown>
                                <MenuButton variant={'plain'} slots={{ root: IconButton }}>
                                    <Avatar/>
                                </MenuButton>

                                <Menu placement={'bottom-end'} variant={'plain'}>
                                    <MenuItem>
                                        <Link color={'neutral'} underline={'none'}>
                                            {user.username}
                                        </Link>
                                    </MenuItem>

                                    <MenuItem>
                                        <Link color={'neutral'} underline={'none'} href={'change-password'}>
                                            Changer mon mot de passe
                                        </Link>
                                    </MenuItem>

                                    <hr className={'dropdown-user m-2'} />

                                    <MenuItem>
                                        <Link color={'neutral'} underline={'none'} href={'#'} onClick={() => {
                                            localStorage.removeItem('token');
                                            window.location.href = '/login';
                                        }}>Se deconnecter</Link>
                                    </MenuItem>
                                </Menu>
                            </Dropdown>
                        </nav>
                        <hr className={'content-separator'} />

                        <div className={'content m-4'}>
                            {children}
                        </div>
                    </div>
                </section>
            )}
        </>
    )
}
