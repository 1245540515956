import axios from 'axios'
import { IDelete, IGet, IPost, IPut } from '../types/request'

const API_URL = process.env.API_URL || 'https://dev-api-neptune.eeven.fr'
const token: string = localStorage.getItem('token') || ''

const headers = {
    "Authorization": `Bearer ${token}`
}

export const get = ({url, options}: IGet): Promise<any> => {
    return axios.get(`${API_URL}/${url}`, {
        ...options,
        headers
    })
}

export const post = ({url, data, options}: IPost): Promise<any> => {
    return axios.post(`${API_URL}/${url}`, data, {
        ...options,
        headers
    })
}

export const put = ({url, data, options}: IPut): Promise<any> => {
    return axios.put(`${API_URL}/${url}`, data, {
        ...options,
        headers
    })
}

export const destroy = ({url, options}: IDelete): Promise<any> => {
    return axios.delete(`${API_URL}/${url}`, {
        ...options,
        headers
    })
}