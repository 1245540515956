import React, {useEffect, useRef, useState} from 'react'
import {Link, useSearchParams} from "react-router-dom";
import {CLayout} from "../../../components";
import {useTranslation} from "react-i18next";
import {
    AspectRatio,
    Autocomplete, AutocompleteOption, Box, Breadcrumbs,
    Button, Card,
    FormControl,
    FormLabel, Grid,
    Input,
    Snackbar, Step, stepClasses, StepIndicator, stepIndicatorClasses, Stepper, styled,
    Typography
} from "@mui/joy";
import {
    Add, AddPhotoAlternate,
    ChevronRight, Delete,
    Edit,
    Euro,
    Event,
    LocationOn,
    MusicNote,
    Store
} from "@mui/icons-material";
import * as yup from "yup";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import {get, mutlipart_post, put} from "../../../api/api";
import Swal from "sweetalert2";
import { Editor } from "react-draft-wysiwyg";
import {MapContainer, Marker, TileLayer, useMap} from "react-leaflet";
import axios from "axios";
import {ContentState, EditorState} from "draft-js";
import L from "leaflet";
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const customIcon = L.icon({
    iconUrl: markerIcon,
    shadowUrl: markerShadow,
    iconSize: [25, 41],
    iconAnchor: [12, 41],
    popupAnchor: [1, -34],
    shadowSize: [41, 41],
});

export const VShowEvent = (): React.ReactElement => {
    const { t } = useTranslation()
    const [searchParams] = useSearchParams()
    const [snackbar, setSnackbar] = useState<{
        open: boolean,
        color: "danger" | "primary" | "neutral" | "success" | "warning" | undefined,
        message: string,
    }>({
        open: false,
        color: 'success',
        message: ''
    })
    const [event, setEvent] = useState({
        name: undefined,
        start_date: undefined,
        end_date: undefined,
        location_name: undefined,
        address: undefined,
        postal_code: undefined,
        city: undefined,
        description: undefined,
        price: undefined,
        localisation: {
            name: ''
        },
        ticketing_link: undefined
    });

    const [id] = useState(searchParams.get('id') || 0)

    const showSwalError = () => {
        Swal.fire({
            title: 'Une erreur est survenue',
            text: 'Veuillez vérifiez les informations transmisent.',
            icon: 'error'
        })
    }

    const setBanner = (file: any) => {
        console.log(file)
        void mutlipart_post({url: 'file_uploads', data: {file: file}})
            .then((res) => {
                console.log(res)
                setFirstFormData({
                    icon: {
                        url: res.data,
                        name: file.name
                    },
                    ...firstFormData
                })
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const [selectedLocalisation, setSelectedLocalisation] = useState<string>();
    const [localisations, setLocalisations] = useState([]);
    const [step, setStep] = useState<number>(1);
    const [address, setAddress] = useState<string>('')

    const [newArtiste, setNewArtiste] = useState<string>('');
    const [artistes, setArtistes] = useState<string[]>([]);
    const addArtiste = () => {
        if (newArtiste.length > 0) {
            setArtistes([
                ...artistes,
                newArtiste
            ])

            setFourthFormData([
                ...artistes,
                newArtiste
            ])
        }
        setNewArtiste('')
    }
    const deleteArtiste = (id: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir retirer cet artiste ?")) {
            let list = artistes;
            list = list.filter((_, index) => index !== id);
            setArtistes(list);
            setFourthFormData(list)
        }
    }
    const [searchCateg, setSearchCateg] = useState<any[]>([]);

    const [orga, setOrga] = useState({
        name: '',
        logo: ''
    })
    const [orgaName, setOrgaName] = useState<string>('')
    const [orgaLogo, setOrgaLogo] = useState<string>('')
    const addOrga = () => {
        setOrga({
            name: orgaName,
            logo: orgaLogo
        })
    }
    const setLogo = (file: any) => {
        console.log(file)
        void mutlipart_post({url: 'file_uploads', data: {file: file}})
            .then((res) => {
                console.log(res)
                setOrgaLogo(res.data.url)
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const [latLng, setLatLng] = useState<any>([46.59822, 2.49561])
    const [zoom, setZoom] = useState<number>(5)

    const [editorState, setEditorState] = useState(
        () => EditorState.createEmpty(),
    );

    const getCoords = (addr: string, zoom: number) => {
        if (addr === "") {
            if (!selectedLocalisation) {
                setLatLng([46.59822, 2.49561])
                setZoom(5)
            } else {
                setZoom(8)
            }
        } else {
            void axios.get(`https://nominatim.openstreetmap.org/search?q=${addr}&format=json&limit=1`)
                .then((response) => {
                    if (response.data[0]?.lat && response.data[0]?.lon) {
                        setLatLng([
                            response.data[0]?.lat,
                            response.data[0]?.lon
                        ])
                        setZoom(zoom)
                    }
                })
        }
    }

    useEffect(() => {
        document.title = `EevenPRO - ${t('Détails d\'un évènement')}`
    }, [t]);

    useEffect(() => {
        void get({url: 'sub-categories'})
            .then(res => {
                setSearchCateg(res.data)
            })
            .catch(err => console.log(err))

        void get({url: `events/${id}`})
            .then(res => {
                setEvent(res.data)

                const start_date = res.data.start_date.split('T')
                setStartDate(start_date[0])
                setStartHour(start_date[1].split('+')[0])
                const end_date = res.data.end_date.split('T')
                setEndDate(end_date[0])
                setEndHour(end_date[1].split('+')[0])

                setFirstFormData({
                    name: res.data.name,
                    description: res.data.description,
                    startDate: start_date[0],
                    startHour: start_date[1],
                    endDate: end_date[0],
                    endHour: end_date[1],
                    price: res.data.price,
                    ticket_link: res.data.ticketing_link
                })

                setSecondFormData({
                    localisation: res.data.localisation.name,
                    location_name: res.data.location_name,
                    address: res.data.address,
                    city: res.data.city,
                })

                setThirdFormData(res.data.sub_categories)

                setArtistes(res.data.artists)
                setFourthFormData(res.data.artists)

                setOrga({
                    name: res.data.organizer_name,
                    logo: ''
                })
                const contentState = ContentState.createFromText(res.data.description || '');
                setEditorState(EditorState.createWithContent(contentState))
            })
            .catch(err => {
            })
    }, [id]);

    useEffect(() => {
        void get({url: 'localisations'})
            .then(res => {
                setLocalisations(res.data)
            })
            .catch(err => console.log(err))
    }, []);

    const [startDate, setStartDate] = useState<string>('');
    const [startHour, setStartHour] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [endHour, setEndHour] = useState<string>('');

    const updateEventValidate = yup.object({
        name: yup.string().required(),
        start_date: yup.date().required(),
        end_date: yup.date().required(),
        location_name: yup.string().required(),
        address: yup.string().required(),
        postal_code: yup.string().required(),
        city: yup.string().required(),
        description: yup.string().required(),
        price: yup.number().required(),
        ticketing_link: yup.string().required(),
    })

    const { register, handleSubmit } = useForm({
        resolver: yupResolver(updateEventValidate)
    })

    const [firstFormData, setFirstFormData] = useState<any>({})
    const checkFirstForm = () => {
        console.log(firstFormData)
        if (!firstFormData.name ||
            !firstFormData.startDate ||
            !firstFormData.startHour ||
            !firstFormData.endDate ||
            !firstFormData.endHour ||
            firstFormData.price === undefined ||
            !firstFormData.ticket_link
        ) {
            showSwalError()
        } else {
            setStep(2)
        }
    }

    const [secondFormData, setSecondFormData] = useState<any>({})
    const checkSecondForm = () => {
        console.log(secondFormData)
        if (
            !secondFormData.localisation ||
            !secondFormData.location_name ||
            !secondFormData.address ||
            !secondFormData.city
        ) {
            showSwalError()
        } else {
            setStep(3)
        }
    }

    const [thirdFormData, setThirdFormData] = useState<any[]>([])
    const checkThirdForm = () => {
        console.log(thirdFormData)
        if (thirdFormData.length === 0) {
            showSwalError()
        } else {
            setStep(4)
        }
    }

    const [fourthFormData, setFourthFormData] = useState<any[]>([])
    const checkFourthForm = () => {
        console.log(fourthFormData)
        if (fourthFormData.length === 0) {
            showSwalError()
        } else {
            setStep(5)
        }
    }

    const submit = async (data: any) => {
        const formValues = {
            ...data,
            ...firstFormData,
            ...secondFormData,
            ...thirdFormData,
            artists: fourthFormData,
            location_coordinates_gps_x: latLng[0],
            location_coordinates_gps_y: latLng[1],
            url_page_scrapping: ''
        }
        console.log(formValues)

        await put({url: `events/${id}`, data: formValues})
            .then(res => {
                setSnackbar({
                    open: true,
                    color: 'success',
                    message: 'Votre évènement à bien été modifié.'
                })
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    color: 'danger',
                    message: 'Une erreur est survenue.'
                })
            });
    }

    const submitFailed = () => {
        Swal.fire({
            title: 'Une erreur est survenue',
            text: 'Veuillez vérifiez les informations transmisent.',
            icon: 'error'
        })
    }

    const fileInputRef = useRef<any>(null);

    const handleAspectRatioClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const UpdateMapView = () => {
        const map = useMap()
        useEffect(() => {
            if (map) {
                map.setView(latLng, zoom)
            }
        }, [map]);

        return null
    }

    return (
        <CLayout>
            <section id={'VCreateEvent'}>
                <Breadcrumbs separator={'>'}>
                    <Link to={'/'}>{t('EevenPRO')}</Link>
                    <Link to={'/events'}>{t('Liste des évènements')}</Link>
                    <Typography>{t('Ajouter un évènement')}</Typography>
                </Breadcrumbs>

                <form onSubmit={handleSubmit(submit, submitFailed)}>
                    <Stepper size={'lg'} className={'!mx-5 !my-4'} sx={{
                        '--StepIndicator-size': '3rem',
                        '--Step-connectorInset': '0px',
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderWidth: 4,
                        },
                        [`& .${stepClasses.root}::after`]: {
                            height: 4,
                        },
                        [`& .${stepClasses.completed}`]: {
                            [`& .${stepIndicatorClasses.root}`]: {
                                borderColor: 'currentColor',
                                color: 'currentColor',
                            },
                            '&::after': {
                                bgcolor: 'currentColor',
                            },
                        },
                        [`& .${stepClasses.active}`]: {
                            [`& .${stepIndicatorClasses.root}`]: {
                                borderColor: 'currentColor',
                            },
                        },
                        [`& .${stepClasses.disabled} *`]: {
                            color: 'neutral.outlinedDisabledColor',
                        },
                    }}>
                        <Step active={step === 1} completed={step > 1} indicator={
                            <StepIndicator variant={'outlined'}>
                                <Event />
                            </StepIndicator>
                        } />

                        <Step active={step === 2} completed={step > 2} indicator={
                            <StepIndicator variant={'outlined'}>
                                <LocationOn />
                            </StepIndicator>
                        } />

                        <Step active={step === 3} completed={step > 3} indicator={
                            <StepIndicator variant={'outlined'}>
                                <Edit />
                            </StepIndicator>
                        } />

                        <Step active={step === 4} completed={step > 4} indicator={
                            <StepIndicator variant={'outlined'}>
                                <MusicNote />
                            </StepIndicator>
                        } />

                        <Step active={step === 5} indicator={
                            <StepIndicator variant={'outlined'}>
                                <Store />
                            </StepIndicator>
                        } />
                    </Stepper>

                    {step === 1 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4} xsOffset={8} className={'!text-end'}>
                                    <Button className={'!py-3 next'} size={'lg'} onClick={checkFirstForm}>
                                        Suivant <ChevronRight className={'!ml-2'} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={8}>
                                                <FormControl className={'mt-2'}>
                                                    <FormLabel>
                                                        {t('Nom de l\'évenement')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <Input
                                                        {...register('name')}
                                                        variant={'outlined'}
                                                        value={event.name}
                                                        onChange={(e) => {
                                                            setFirstFormData({
                                                                name: e.target.value,
                                                                ...firstFormData
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={12}>
                                                <FormControl>
                                                    <FormLabel>
                                                        {t('Description de l\'événement')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <Editor
                                                        editorState={editorState}
                                                        toolbarClassName="toolbarClassName"
                                                        wrapperClassName="wrapperClassName"
                                                        editorClassName="editorClassName"
                                                        onEditorStateChange={setEditorState}
                                                        wrapperStyle={{
                                                            background: '#FFFDFA',
                                                            filter: 'drop-shadow(0px 0px 2px #00000050)',
                                                            borderRadius: '5px',
                                                        }}
                                                        editorStyle={{
                                                            height: '200px',
                                                            padding: '10px'
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={12}>
                                                <FormControl>
                                                    <FormLabel>
                                                        {t('Affiche de l\'évenement')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <AspectRatio
                                                        onClick={handleAspectRatioClick}
                                                        sx={{
                                                            border: '2px dashed',
                                                            borderColor: 'neutral.outlinedBorder',
                                                            borderRadius: 'md',
                                                            cursor: 'pointer',
                                                            '&:hover': { borderColor: 'primary.outlinedBorder' },
                                                        }}
                                                        maxHeight={150}
                                                    >
                                                        <Box textAlign={"center"} flexDirection={'column'}>
                                                            <AddPhotoAlternate fontSize="large" color="disabled" />
                                                            <Typography>1920×1080</Typography>
                                                            <Typography>Image</Typography>
                                                            {firstFormData.icon?.name && (
                                                                <Typography className={'mt-2'}>
                                                                    Fichier sélectionné : {firstFormData.icon?.name}
                                                                </Typography>
                                                            )}
                                                        </Box>
                                                        <VisuallyHiddenInput ref={fileInputRef} type="file" onChange={(e) => {
                                                            if (e.target?.files) {
                                                                setBanner(e.target?.files[0])
                                                            }
                                                        }} />
                                                    </AspectRatio>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={12}>
                                                <FormLabel>
                                                    {t('Début')}
                                                    <span className={'text-red-500'}>*</span>
                                                </FormLabel>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Input
                                                    {...register('start_date')}
                                                    variant={'outlined'}
                                                    type={'date'}
                                                    value={startDate}
                                                    onChange={(e) => {
                                                        setFirstFormData({
                                                            startDate: e.target?.value,
                                                            ...firstFormData
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={3}>
                                                <Input
                                                    variant={'outlined'}
                                                    type={'time'}
                                                    value={startHour}
                                                    onChange={(e) => {
                                                        setFirstFormData({
                                                            startHour: e.target?.value,
                                                            ...firstFormData
                                                        })
                                                    }}
                                                />
                                            </Grid>

                                            <Grid xs={12}>
                                                <FormLabel>
                                                    {t('Fin')}
                                                    <span className={'text-red-500'}>*</span>
                                                </FormLabel>
                                            </Grid>
                                            <Grid xs={6}>
                                                <Input
                                                    {...register('end_date')}
                                                    variant={'outlined'}
                                                    type={'date'}
                                                    value={endDate}
                                                    onChange={(e) => {
                                                        setFirstFormData({
                                                            endDate: e.target?.value,
                                                            ...firstFormData
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                            <Grid xs={3}>
                                                <Input
                                                    variant={'outlined'}
                                                    type={'time'}
                                                    value={endHour}
                                                    onChange={(e) => {
                                                        setFirstFormData({
                                                            endHour: e.target?.value,
                                                            ...firstFormData
                                                        })
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>

                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={3}>
                                                <FormControl>
                                                    <FormLabel>
                                                        {t('Prix de l\'évenement')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <Input
                                                        {...register('price')}
                                                        variant={'outlined'}
                                                        type={'number'}
                                                        value={event.price}
                                                        defaultValue={0.00}
                                                        endDecorator={<Euro />}
                                                        slotProps={{
                                                            input: {
                                                                step: '0.01',
                                                                min: '0',
                                                            }
                                                        }}
                                                        onChange={(e) => {
                                                            setFirstFormData({
                                                                price: e.target.value,
                                                                ...firstFormData
                                                            })
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={6}>
                                                <FormControl>
                                                    <FormLabel>
                                                        {t('Lien vers la billeterie')}
                                                        <span className={'text-red-500'}>*</span>
                                                    </FormLabel>
                                                    <Input
                                                        {...register('ticketing_link')}
                                                        variant={'outlined'}
                                                        value={event.ticketing_link}
                                                        onChange={(e) => {
                                                            setFirstFormData({
                                                                ticket_link: e.target?.value,
                                                                ...firstFormData
                                                            })
                                                        }}
                                                    />
                                                    <small><i>Coller l'url de la billeterie</i></small>
                                                </FormControl>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {step === 2 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4}>
                                    <Button className={'!py-3 prev'} size={'lg'} variant={'outlined'} color={'neutral'} onClick={() => setStep(1)}>
                                        Précédent
                                    </Button>
                                </Grid>

                                <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                    <Button className={'!py-3 next'} size={'lg'} onClick={checkSecondForm}>
                                        Suivant <ChevronRight className={'!ml-2'} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card className={'cards_events mx-5 !p-5 h-[400px]'}>
                                        <Grid container spacing={2} className={'!h-full'}>
                                            <Grid xs={7} className={'!h-full'}>
                                                <MapContainer
                                                    center={latLng}
                                                    zoom={zoom}
                                                    style={{ height: "100%", width: "100%", borderRadius: "1rem" }}
                                                >
                                                    <TileLayer
                                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery by <a href="https://carto.com/attributions">CARTO</a>'
                                                    />
                                                    {address && (
                                                        <Marker position={latLng} icon={customIcon} />
                                                    )}
                                                    <UpdateMapView />
                                                </MapContainer>
                                            </Grid>

                                            <Grid container spacing={2} xs={5}>
                                                <Grid xs={12}>
                                                    <FormControl>
                                                        <FormLabel>{t('Localisation')}</FormLabel>
                                                        <Autocomplete
                                                            onChange={(event, newValue) => {
                                                                setSelectedLocalisation(newValue?.name ?? '')
                                                                setSecondFormData({
                                                                    localisation: newValue?.name,
                                                                    ...secondFormData
                                                                })
                                                                let addr: any = newValue?.name
                                                                if (addr) {
                                                                    addr = addr.split(' ')
                                                                    getCoords(addr[0], 8)
                                                                } else {
                                                                    getCoords('', 5)
                                                                }
                                                            }}
                                                            options={localisations}
                                                            value={!selectedLocalisation
                                                                ? event?.localisation
                                                                : localisations.find((loc: any) => loc.name === selectedLocalisation) || null}
                                                            getOptionLabel={(option: any) => option.name || ''}
                                                            isOptionEqualToValue={(option: any, value: any) => option.name === value}

                                                        />
                                                    </FormControl>
                                                </Grid>

                                                {(selectedLocalisation || event?.localisation) && (
                                                    <>
                                                        <Grid xs={12}>
                                                            <FormControl>
                                                                <FormLabel>{t('Lieu de l\'évenement')}</FormLabel>
                                                                <Input
                                                                    {...register('location_name')}
                                                                    value={event.location_name}
                                                                    onChange={(e) => {
                                                                        setSecondFormData({
                                                                            location_name: e.target.value,
                                                                            ...secondFormData
                                                                        })
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid xs={12}>
                                                            <FormControl>
                                                                <FormLabel>{t('Adresse complète')}</FormLabel>
                                                                <Input
                                                                    {...register('address')}
                                                                    value={event.address}
                                                                    onChange={(e) => {
                                                                        getCoords(e.target.value, 13)
                                                                        setAddress(e.target.value)
                                                                        setSecondFormData({
                                                                            address: e.target.value,
                                                                            ...secondFormData
                                                                        })
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>

                                                        <Grid xs={12}>
                                                            <FormControl>
                                                                <FormLabel>{t('Ville')}</FormLabel>
                                                                <Input
                                                                    {...register('city')}
                                                                    value={event.city}
                                                                    onChange={(e: any) => {
                                                                        setSecondFormData({
                                                                            city: e.target.value,
                                                                            ...secondFormData
                                                                        })
                                                                    }}
                                                                />
                                                            </FormControl>
                                                        </Grid>
                                                    </>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {step === 3 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4}>
                                    <Button className={'!py-3 prev'} size={'lg'} variant={'outlined'} color={'neutral'} onClick={() => setStep(2)}>
                                        Précédent
                                    </Button>
                                </Grid>

                                <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                    <Button className={'!py-3 next'} size={'lg'} onClick={checkThirdForm}>
                                        Suivant <ChevronRight className={'!ml-2'} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card variant={'soft'} className={'mx-5 !p-5 cards_events'}>
                                        <FormControl>
                                            <FormLabel>Ajouter une catégorie</FormLabel>
                                            <Autocomplete
                                                autoComplete
                                                options={searchCateg}
                                                value={thirdFormData}
                                                multiple
                                                onChange={(_, newValue) => {
                                                    setThirdFormData(newValue)
                                                }}
                                                noOptionsText="Aucune catégorie ne correspond à votre recherche"
                                                getOptionLabel={(option: any) => option?.category?.name && option?.name ? `${option.category.name} (${option.name})` : option.name}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                renderOption={(props, option) => (
                                                    <AutocompleteOption {...props}>
                                                        {option.category.name} ({option.name})
                                                    </AutocompleteOption>
                                                )}
                                                className={""}
                                            />
                                        </FormControl>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {step === 4 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4}>
                                    <Button className={'!py-3 prev'} size={'lg'} variant={'outlined'} color={'neutral'} onClick={() => setStep(3)}>
                                        Précédent
                                    </Button>
                                </Grid>

                                <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                    <Button className={'!py-3 next'} size={'lg'} onClick={checkFourthForm}>
                                        Suivant <ChevronRight className={'!ml-2'} />
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card className={'mx-5 !p-5 cards_events'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={6}>
                                                <FormControl>
                                                    <FormLabel>Nom de l'artiste</FormLabel>
                                                    <Input
                                                        variant={'outlined'}

                                                        value={newArtiste}
                                                        onChange={(e) => setNewArtiste(e.currentTarget.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={'auto'}>
                                                <Button className={'!mt-6'} color={'success'} onClick={addArtiste}>
                                                    <Add />
                                                </Button>
                                            </Grid>

                                            <Grid container spacing={2} xs={12}>
                                                {artistes.map((artiste, key) => (
                                                    <Grid key={key} xs={4}>
                                                        <Card key={key} className={'hover:drop-shadow-lg'}>
                                                            <Grid container spacing={2}>
                                                                <Grid spacing={0} container xs={9}>
                                                                    <Grid xs={12}>
                                                                        <Typography level={'h4'}>
                                                                            {artiste}
                                                                        </Typography>
                                                                    </Grid>
                                                                </Grid>

                                                                <Grid xs={3}>
                                                                    <Button variant={'outlined'} color={'danger'} onClick={() => deleteArtiste(key)}>
                                                                        <Delete />
                                                                    </Button>
                                                                </Grid>
                                                            </Grid>
                                                        </Card>
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}

                    {step === 5 && (
                        <>
                            <Grid container spacing={2} className={'!mt-3 !mx-3'}>
                                <Grid xs={4}>
                                    <Button className={'!py-3 prev'} size={'lg'} variant={'outlined'} color={'neutral'}
                                            onClick={() => setStep(4)}>
                                        Précédent
                                    </Button>
                                </Grid>

                                <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                    <Button type={'submit'} className={'!py-3 submit'} size={'lg'} color={'success'}>
                                        Ajouter l'évènement
                                    </Button>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid xs={12}>
                                    <Card className={'mx-5 !p-5 cards_events'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={3}>
                                                <FormControl>
                                                    <FormLabel>Nom de l'organisateur</FormLabel>
                                                    <Input
                                                        variant={'outlined'}
                                                        onChange={(e) => setOrgaName(e.target.value)}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={3}>
                                                <FormControl>
                                                    <FormLabel>Logo de l'organisateur</FormLabel>
                                                    <Input
                                                        variant={'outlined'}
                                                        type={'file'}
                                                        onChange={(e) => {
                                                            if (e.target?.files) {
                                                                setLogo(e.target?.files[0])
                                                            }
                                                        }}
                                                    />
                                                </FormControl>
                                            </Grid>

                                            <Grid xs={'auto'}>
                                                <Button className={'!mt-6'} color={'success'} onClick={addOrga}>
                                                    <Add />
                                                </Button>
                                            </Grid>

                                            <Grid xs={12}>
                                                {orga.name && (
                                                    <Card className={'hover:drop-shadow-lg'}>
                                                        <Grid container spacing={2}>
                                                            <Grid xs={'auto'}>
                                                                <div className={'w-[100px] h-[65px]'} style={{
                                                                    backgroundImage: `url(${orga.logo})`,
                                                                    backgroundRepeat: 'no-repeat',
                                                                    backgroundSize: 'contain',
                                                                }} />
                                                            </Grid>

                                                            <Grid spacing={0} container xs={9}>
                                                                <Grid xs={12}>
                                                                    <Typography level={'h4'}>
                                                                        {orga.name}
                                                                    </Typography>
                                                                </Grid>
                                                            </Grid>

                                                            <Grid xs={'auto'} className={'!w-max'}>
                                                                <Button variant={'outlined'} color={'danger'} className={'!w-full !h-full'}
                                                                        onClick={() => setOrga({name: '', logo: ''})}>
                                                                    <Delete/>
                                                                </Button>
                                                            </Grid>
                                                        </Grid>
                                                    </Card>
                                                )}
                                            </Grid>
                                        </Grid>
                                    </Card>
                                </Grid>
                            </Grid>
                        </>
                    )}
                </form>

                <Snackbar open={snackbar.open} variant={'soft'} color={snackbar.color} autoHideDuration={3000} onClose={() => setSnackbar({...snackbar, open: false})}>
                    {snackbar.message}
                </Snackbar>
            </section>
        </CLayout>
    )
}