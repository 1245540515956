import React, {useEffect, useState} from 'react'
import {useTranslation} from "react-i18next";
import { useForm } from 'react-hook-form'
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from 'yup'
import {MapContainer, Marker, TileLayer} from "react-leaflet";
import { Editor } from '@tinymce/tinymce-react'
import "leaflet/dist/leaflet.css";

import {
    Autocomplete, Breadcrumbs,
    Button, Card,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Snackbar, Step, StepIndicator, Stepper, Table, Typography,
    stepClasses, stepIndicatorClasses, AutocompleteOption
} from "@mui/joy";
import Swal from "sweetalert2";
import {
    Add,
    Check,
    ChevronRight, Close, Delete,
    Edit,
    Euro, Event,
    LocationOn, MusicNote, Store,
} from "@mui/icons-material";
import {Link} from "react-router-dom";
import axios from "axios";

import {get, post} from "../../../api/api";
import {CLayout} from "../../../components";

export const VCreateEvent = (): React.ReactElement => {
    const { t } = useTranslation()

    const [snackbar, setSnackbar] = useState<{
        open: boolean,
        color: "danger" | "primary" | "neutral" | "success" | "warning" | undefined,
        message: string,
    }>({
        open: false,
        color: 'success',
        message: ''
    })

    const [selectedLocalisation, setSelectedLocalisation] = useState({});
    const [localisations, setLocalisations] = useState([]);
    const [step, setStep] = useState<number>(1);

    const [openCategory, setOpenCategory] = useState<boolean>(false);
    const [openArtiste, setOpenArtiste] = useState<boolean>(false);
    const [openOrga, setOpenOrga] = useState<boolean>(false);

    const [newArtiste, setNewArtiste] = useState<string>('');
    const [artistes, setArtistes] = useState<string[]>([]);
    const addArtiste = () => {
        if (newArtiste.length > 0) {
            setArtistes([
                ...artistes,
                newArtiste
            ])
        }
        setNewArtiste('')
    }
    const deleteArtiste = (id: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir retirer cet artiste ?")) {
            let list = artistes;
            list = list.filter((_, index) => index !== id);
            setArtistes(list);
        }
    }

    const [listCateg, setListCateg] = useState<any[]>([]);
    const [categ, setCateg] = useState<any>(null);
    const addCateg = () => {
        if (categ) {
            setListCateg([
                ...listCateg,
                categ
            ])
            setCateg(null)
        }
    }
    const deleteCateg = (id: number) => {
        if (window.confirm("Êtes-vous sûr de vouloir retirer cette categorie ?")) {
            let list = listCateg;
            list = list.filter((_, index) => index !== id);
            setListCateg(list);
        }
    }
    const [searchCateg, setSearchCateg] = useState<any[]>([]);

    const [orga, setOrga] = useState({
        name: '',
        logo: ''
    })
    const [orgaName, setOrgaName] = useState<string>('')
    const [orgaLogo] = useState<string>('')
    const addOrga = () => {
        setOrga({
            name: orgaName,
            logo: orgaLogo
        })
    }
    const setLogo = (e: any) => {

    }

    const [latLng, setLatLng] = useState<any>([46.59822, 2.49561])
    const [zoom, setZoom] = useState<number>(5)

    const getCoords = (addr: string) => {
        if (addr === "") {
            setLatLng([46.59822, 2.49561])
            setZoom(5)
        } else {
            void axios.get(`https://nominatim.openstreetmap.org/search?q=${addr}&format=json&limit=1`)
                .then((response) => {
                    setLatLng([
                        response.data[0]?.lat,
                        response.data[0]?.lon
                    ])
                    setZoom(13)
                })
        }
    }

    const createEventValidate = yup.object({
        name: yup.string().required(),
        start_date: yup.date().required(),
        end_date: yup.date().required(),
        location_name: yup.string().required(),
        address: yup.string().required(),
        postal_code: yup.string().required(),
        city: yup.string().required(),
        description: yup.string().required(),
        price: yup.number().required(),
        ticketing_link: yup.string().required(),
    })

    const { register, handleSubmit } = useForm({
        resolver: yupResolver(createEventValidate)
    })

    const submit = async (data: any) => {
        const formValues = {
            ...data,

            urls_image: [],
            ticketing_link: '',
            artists: artistes,
            organizerName: orga.name,
            localisation: selectedLocalisation,
            location_coordinates_gps_x: latLng[0],
            location_coordinates_gps_y: latLng[1],
            url_page_scrapping: ''
        }

        await post({url: 'events', data: formValues})
            .then(res => {
                setSnackbar({
                    open: true,
                    color: 'success',
                    message: 'Votre évènement à bien été crée.'
                })
                window.location.href = `/events/show?id=${res.data.id}`
            })
            .catch(err => {
                setSnackbar({
                    open: true,
                    color: 'danger',
                    message: 'Une erreur est survenue.'
                })
            });
    }
    const submitFailed = () => {
        Swal.fire({
            title: 'Une erreur est survenue',
            text: 'Veuillez vérifiez les informations transmisent.',
            icon: 'error'
        })
    }

    useEffect(() => {
        document.title = `EevenPRO - ${t('Créer un évènement')}`
    }, [t]);

    useEffect(() => {
        void get({url: 'localisations'})
            .then(res => {
                setLocalisations(res.data)
            })
            .catch(err => console.log(err))

        void get({url: 'sub-categories'})
            .then(res => {
                setSearchCateg(res.data)
            })
            .catch(err => console.log(err))
    }, []);

    return (
        <CLayout>
            <Breadcrumbs separator={'>'}>
                <Link to={'/'}>{t('EevenPRO')}</Link>
                <Link to={'/events'}>{t('Liste des évènements')}</Link>
                <Typography>{t('Ajouter un évènement')}</Typography>
            </Breadcrumbs>

            <form onSubmit={handleSubmit(submit, submitFailed)}>
                <Stepper size={'lg'} className={'!mx-5 !my-4'} sx={{
                    '--StepIndicator-size': '3rem',
                    '--Step-connectorInset': '0px',
                    [`& .${stepIndicatorClasses.root}`]: {
                        borderWidth: 4,
                    },
                    [`& .${stepClasses.root}::after`]: {
                        height: 4,
                    },
                    [`& .${stepClasses.completed}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'currentColor',
                            color: 'currentColor',
                        },
                        '&::after': {
                            bgcolor: 'currentColor',
                        },
                    },
                    [`& .${stepClasses.active}`]: {
                        [`& .${stepIndicatorClasses.root}`]: {
                            borderColor: 'currentColor',
                        },
                    },
                    [`& .${stepClasses.disabled} *`]: {
                        color: 'neutral.outlinedDisabledColor',
                    },
                }}>
                    <Step active={step === 1} completed={step > 1} indicator={
                        <StepIndicator variant={'outlined'}>
                            <Event />
                        </StepIndicator>
                    } />

                    <Step active={step === 2} completed={step > 2} indicator={
                        <StepIndicator variant={'outlined'}>
                            <LocationOn />
                        </StepIndicator>
                    } />

                    <Step active={step === 3} completed={step > 3} indicator={
                        <StepIndicator variant={'outlined'}>
                            <Edit />
                        </StepIndicator>
                    } />

                    <Step active={step === 4} completed={step > 4} indicator={
                        <StepIndicator variant={'outlined'}>
                            <MusicNote />
                        </StepIndicator>
                    } />

                    <Step active={step === 5} indicator={
                        <StepIndicator variant={'outlined'}>
                            <Store />
                        </StepIndicator>
                    } />
                </Stepper>

                {step === 1 && (
                    <Card sx={{background: '#FFF'}} variant={'soft'} className={'border border-black mx-5 !p-5'}>
                        <Typography level={'h4'}>Informations sur l'événement</Typography>

                        <Grid container spacing={2}>
                            <Grid xs={8}>
                                <FormControl className={'mt-2'}>
                                    <FormLabel>
                                        {t('Nom de l\'évenement')}
                                    </FormLabel>
                                    <Input
                                        {...register('name')}
                                        variant={'outlined'}
                                        className={'!bg-gray-200 !shadow-sm'}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={4}>
                                <FormControl className={'mt-2'}>
                                    <FormLabel>{t('Affiche de l\'évenement')}</FormLabel>
                                    <Input
                                        type={'file'}
                                        variant={'outlined'}
                                        className={'!bg-gray-200 !shadow-sm'}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={12}>
                                <FormControl>
                                    <FormLabel>{t('Description de l\'événement')}</FormLabel>
                                    <Editor
                                        apiKey='plcr9x9nu6gqvdmsc5krdba7schah27mgidj4j9xtk76r2kw'
                                        init={{
                                            language: 'fr_FR',
                                            height: 300,
                                            menubar: false,
                                            plugins: [
                                                'a11ychecker','advlist','advcode','advtable','autolink','checklist','export',
                                                'lists','link','image','charmap','preview','anchor','searchreplace','visualblocks',
                                                'powerpaste','fullscreen','formatpainter','insertdatetime','media','table','help','wordcount'
                                            ],
                                            toolbar: 'undo redo | casechange blocks | bold italic backcolor | ' +
                                                'alignleft aligncenter alignright alignjustify | ' +
                                                'bullist numlist checklist outdent indent | removeformat | a11ycheck code table help',
                                            content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px; background-color: #e5e7eb }'
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={5}>
                                <FormControl>
                                    <FormLabel>{t('Début de l\'évenement')}</FormLabel>
                                    <Input
                                        {...register('start_date')}
                                        variant={'outlined'}
                                        type={'datetime-local'}
                                        className={'!bg-gray-200 !shadow-sm'}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={5}>
                                <FormControl>
                                    <FormLabel>{t('Fin de l\'évenement')}</FormLabel>
                                    <Input
                                        {...register('end_date')}
                                        variant={'outlined'}
                                        type={'datetime-local'}
                                        className={'!bg-gray-200 !shadow-sm'}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={2}></Grid>

                            <Grid xs={3}>
                                <FormControl>
                                    <FormLabel>{t('Prix de l\'évenement')}</FormLabel>
                                    <Input
                                        {...register('price')}
                                        variant={'outlined'}
                                        type={'number'}
                                        className={'!bg-gray-200 !shadow-sm'}
                                        defaultValue={0.00}
                                        endDecorator={<Euro />}
                                        slotProps={{
                                            input: {
                                                step: '0.01',
                                                min: '0',
                                            }
                                        }}
                                    />
                                </FormControl>
                            </Grid>

                            <Grid xs={7}>
                                <FormControl>
                                    <FormLabel>{t('Lien vers la billeterie')}</FormLabel>
                                    <Input
                                        {...register('ticketing_link')}
                                        variant={'outlined'}
                                        className={'!bg-gray-200 !shadow-sm'}
                                    />
                                    <small><i>Coller l'url de la billeterie</i></small>
                                </FormControl>
                            </Grid>

                            <Grid xs={2}></Grid>
                        </Grid>

                        <Grid container spacing={2} className={'!mt-3'}>
                            <Grid xs={4} xsOffset={8} className={'!text-end'}>
                                <Button className={'!py-3'} size={'lg'} onClick={() => setStep(2)}>
                                    Suivant <ChevronRight className={'!ml-2'} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                )}

                {step === 2 && (
                    <Card sx={{background: '#FFF'}} variant={'soft'} className={'border border-black mx-5 !p-5'}>
                        <Grid container spacing={2}>
                            <Grid container spacing={2} xs={5}>
                                <Grid xs={12}>
                                    <FormControl>
                                        <FormLabel>{t('Localisation')}</FormLabel>
                                        <Autocomplete
                                            onChange={(event, newValue) => {
                                                setSelectedLocalisation(newValue?.id)
                                            }}
                                            options={localisations}
                                            getOptionLabel={(option: any) => option.name || ''}
                                            isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                            className={'!bg-gray-200 !shadow-sm'}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid xs={12}>
                                    <FormControl>
                                        <FormLabel>{t('Lieu de l\'évenement')}</FormLabel>
                                        <Input
                                            {...register('location_name')}
                                            className={'!bg-gray-200 !shadow-sm'}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid xs={12}>
                                    <FormControl>
                                        <FormLabel>{t('Adresse complète')}</FormLabel>
                                        <Input
                                            {...register('address')}
                                            className={'!bg-gray-200 !shadow-sm'}
                                            onChange={(e) => {getCoords(e.target.value)}}
                                        />
                                    </FormControl>
                                </Grid>

                                <Grid xs={12}>
                                    <FormControl>
                                        <FormLabel>{t('Ville')}</FormLabel>
                                        <Input
                                            {...register('city')}
                                            className={'!bg-gray-200 !shadow-sm'}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>

                            <Grid xs={7}>
                                <MapContainer
                                    center={latLng}
                                    zoom={zoom}
                                    style={{ height: "100%", width: "100%", borderRadius: "1rem" }}
                                >
                                    <TileLayer
                                        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}{r}.png"
                                        attribution='Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, Imagery by <a href="https://carto.com/attributions">CARTO</a>'
                                    />
                                    <Marker position={latLng} />
                                </MapContainer>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className={'!mt-3'}>
                            <Grid xs={4}>
                                <Button className={'!py-3'} size={'lg'} variant={'outlined'} color={'neutral'} onClick={() => setStep(1)}>
                                    Précédent
                                </Button>
                            </Grid>

                            <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                <Button className={'!py-3'} size={'lg'} onClick={() => setStep(3)}>
                                    Suivant <ChevronRight className={'!ml-2'} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                )}

                {step === 3 && (
                    <Card sx={{background: '#FFF'}} variant={'soft'} className={'border border-black mx-5 !p-5'}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Grid container spacing={2} className={'!mt-1'}>
                                    <Grid xs={4}>
                                        <Button onClick={() => {setOpenCategory(true)}} className={'!py-2'} size={'lg'} variant={'outlined'} color={'neutral'}>
                                            <Add className={'mr-2'} /> Ajouter une catégorie
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {openCategory && (
                                <>
                                    <Grid xs={6}>
                                        <FormControl>
                                            <FormLabel>Rechercher une catégorie</FormLabel>
                                            <Autocomplete
                                                autoComplete
                                                options={searchCateg}
                                                value={categ}
                                                onChange={(_, newValue) => setCateg(newValue)}
                                                noOptionsText="Aucune catégorie ne correspond à votre recherche"
                                                getOptionLabel={(option: any) => option?.category?.name && option?.name ? `${option.category.name} (${option.name})` : ''}
                                                isOptionEqualToValue={(option, value) => option.name === value.name}
                                                renderOption={(props, option) => (
                                                    <AutocompleteOption {...props}>
                                                        {option.category.name} ({option.name})
                                                    </AutocompleteOption>
                                                )}
                                                className={"!bg-gray-200 !shadow-sm"}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={'auto'}>
                                        <Button className={'!mt-6'} variant={'outlined'} color={'success'} onClick={addCateg}>
                                            <Check />
                                        </Button>
                                    </Grid>

                                    <Grid xs={'auto'}>
                                        <Button className={'!mt-6'} variant={'outlined'} color={'danger'} onClick={() => setOpenCategory(false)}>
                                            <Close />
                                        </Button>
                                    </Grid>
                                </>
                            )}

                            <Grid xs={12}>
                                <Table size={'lg'} stickyHeader>
                                    <thead>
                                        <tr>
                                            <th className={'!bg-gray-500 !text-white'}>Categorie</th>
                                            <th className={'!bg-gray-500 !text-white'}>Sous-categorie</th>
                                            <th className={'!bg-gray-500 !text-white'}>Actions</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {listCateg.map((item:any, index: number) => (
                                            <tr key={index}>
                                                <td>{item.category.name}</td>
                                                <td>{item.name}</td>
                                                <td>
                                                    <Button color={'danger'} variant={'outlined'} onClick={() => deleteCateg(index)}>
                                                        <Delete />
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className={'!mt-3'}>
                            <Grid xs={4}>
                                <Button className={'!py-3'} size={'lg'} variant={'outlined'} color={'neutral'} onClick={() => setStep(2)}>
                                    Précédent
                                </Button>
                            </Grid>

                            <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                <Button className={'!py-3'} size={'lg'} onClick={() => setStep(4)}>
                                    Suivant <ChevronRight className={'!ml-2'} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                )}

                {step === 4 && (
                    <Card sx={{background: '#FFF'}} variant={'soft'} className={'border border-black mx-5 !p-5'}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Grid container spacing={2} className={'!mt-1'}>
                                    <Grid xs={4}>
                                        <Button onClick={() => {setOpenArtiste(true)}} className={'!py-2'} size={'lg'} variant={'outlined'} color={'neutral'}>
                                            <Add className={'mr-2'} /> Ajouter un artiste
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {openArtiste && (
                                <>
                                    <Grid xs={6}>
                                        <FormControl>
                                            <FormLabel>Nom de l'artiste</FormLabel>
                                            <Input
                                                variant={'outlined'}
                                                className={'!bg-gray-200 !shadow-sm'}
                                                value={newArtiste}
                                                onChange={(e) => setNewArtiste(e.currentTarget.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={'auto'}>
                                        <Button className={'!mt-6'} variant={'outlined'} color={'success'} onClick={addArtiste}>
                                            <Add />
                                        </Button>
                                    </Grid>

                                    <Grid xs={'auto'}>
                                        <Button className={'!mt-6'} variant={'outlined'} color={'danger'} onClick={() => setOpenArtiste(false)}>
                                            <Close />
                                        </Button>
                                    </Grid>
                                </>
                            )}

                            <Grid container spacing={2} xs={12}>
                                {artistes.map((artiste, key) => (
                                    <Grid key={key} xs={12}>
                                        <Card key={key} className={'hover:drop-shadow-lg'}>
                                            <Grid container spacing={2}>
                                                <Grid spacing={0} container xs={11}>
                                                    <Grid xs={12}>
                                                        <Typography level={'h4'}>
                                                            {artiste}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>

                                                <Grid xs={1}>
                                                    <Button variant={'outlined'} color={'danger'} onClick={() => deleteArtiste(key)}>
                                                        <Delete />
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className={'!mt-3'}>
                            <Grid xs={4}>
                                <Button className={'!py-3'} size={'lg'} variant={'outlined'} color={'neutral'} onClick={() => setStep(3)}>
                                    Précédent
                                </Button>
                            </Grid>

                            <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                <Button className={'!py-3'} size={'lg'} onClick={() => setStep(5)}>
                                    Suivant <ChevronRight className={'!ml-2'} />
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                )}

                {step === 5 && (
                    <Card sx={{background: '#FFF'}} variant={'soft'} className={'border border-black mx-5 !p-5'}>
                        <Grid container spacing={2}>
                            <Grid xs={12}>
                                <Grid container spacing={2} className={'!mt-1'}>
                                    <Grid xs={4}>
                                        <Button onClick={() => {setOpenOrga(true)}} className={'!py-2'} size={'lg'} variant={'outlined'} color={'neutral'}>
                                            <Add className={'mr-2'} /> Ajouter un organisateur
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>

                            {openOrga && (
                                <>
                                    <Grid xs={3}>
                                        <FormControl>
                                            <FormLabel>Nom de l'organisateur</FormLabel>
                                            <Input
                                                variant={'outlined'}
                                                className={'!bg-gray-200 !shadow-sm'}
                                                onChange={(e) => setOrgaName(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={3}>
                                        <FormControl>
                                            <FormLabel>Logo de l'organisateur</FormLabel>
                                            <Input
                                                variant={'outlined'}
                                                className={'!bg-gray-200 !shadow-sm'}
                                                type={'file'}
                                                onChange={(e) => setLogo(e.target.value)}
                                            />
                                        </FormControl>
                                    </Grid>

                                    <Grid xs={'auto'}>
                                        <Button className={'!mt-6'} variant={'outlined'} color={'success'} onClick={addOrga}>
                                            <Add />
                                        </Button>
                                    </Grid>

                                    <Grid xs={'auto'}>
                                        <Button className={'!mt-6'} variant={'outlined'} color={'danger'} onClick={() => setOpenOrga(false)}>
                                            <Close />
                                        </Button>
                                    </Grid>
                                </>
                            )}

                            <Grid xs={12}>
                                {orga.name && (
                                    <Card className={'hover:drop-shadow-lg'}>
                                        <Grid container spacing={2}>
                                            <Grid xs={'auto'}>
                                                <div className={'w-[100px] h-[65px]'} style={{
                                                    backgroundImage: `url(${orga.logo})`,
                                                    backgroundRepeat: 'no-repeat',
                                                    backgroundSize: 'contain',
                                                }} />
                                            </Grid>

                                            <Grid spacing={0} container xs={9}>
                                                <Grid xs={12}>
                                                    <Typography level={'h4'}>
                                                        {orga.name}
                                                    </Typography>
                                                </Grid>
                                            </Grid>

                                            <Grid xs={'auto'} className={'!w-max'}>
                                                <Button variant={'outlined'} color={'danger'} className={'!w-full !h-full'}
                                                        onClick={() => setOrga({name: '', logo: ''})}>
                                                    <Delete/>
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Card>
                                )}
                            </Grid>
                        </Grid>

                        <Grid container spacing={2} className={'!mt-3'}>
                            <Grid xs={4}>
                                <Button className={'!py-3'} size={'lg'} variant={'outlined'} color={'neutral'}
                                        onClick={() => setStep(4)}>
                                    Précédent
                                </Button>
                            </Grid>

                            <Grid xs={4} xsOffset={4} className={'!text-end'}>
                                <Button type={'submit'} className={'!py-3'} size={'lg'} variant={'outlined'} color={'success'}>
                                    Ajouter l'évènement
                                </Button>
                            </Grid>
                        </Grid>
                    </Card>
                )}
            </form>

            <Snackbar open={snackbar.open} variant={'soft'} color={snackbar.color} autoHideDuration={3000} onClose={() => setSnackbar({...snackbar, open: false})}>
                {snackbar.message}
            </Snackbar>
        </CLayout>
    )
}