import { createBrowserRouter } from 'react-router-dom'

import {
    VLogin,
    VReset,

    VEvents,
    VCreateEvent, VShowEvent
} from '../../views'

import {
    CNotFound
} from '../../components'

export const router = createBrowserRouter([
    { path: 'login', Component: VLogin },
    { path: 'forgot-password', Component: VReset },
    { path: 'events', Component: VEvents },
    { path: 'events/create', Component: VCreateEvent },
    { path: 'events/show', Component: VShowEvent },

    { path: '*', Component: CNotFound }
])
