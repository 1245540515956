import {ISidebarItems} from "../../types/sidebarItems";
import {Dashboard, PeopleAlt} from "@mui/icons-material";

export const SidebarItems: ISidebarItems[] = [
    {
        id: 'dashboard',
        title: 'Dashboard',
        url: '#',
        icon: <Dashboard />,
        submenu: true,
        subItems: [
            { id: 'default', title: 'Default', url: '#' },
        ]
    },
    {
        id: 'events',
        title: 'Évènements',
        url: '/events',
        icon: <PeopleAlt />
    }
]