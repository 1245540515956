import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { router } from './plugins/router';
import {loggedRoutes} from "./plugins/router/logged";

import './styles/base.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const App = () => {
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token && loggedRoutes.includes(window.location.pathname)) {
            window.location.href = '/login'
        } else if (token && !loggedRoutes.includes(window.location.pathname)) {
            window.location.href = '/';
        }
    }, []);

    return (
        <React.StrictMode>
            <RouterProvider router={router} />
        </React.StrictMode>
    )
}

root.render(
  <App />
);
