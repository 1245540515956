export const loggedRoutes: string[] = [
    '',
    '/',

    '/events',
    '/events/',
    '/events/create',
    '/events/create/',
    '/events/show',
    '/events/show/',
]
