import React, {useEffect, useState} from "react";

import {CLayout} from "../../components";
import {useTranslation} from "react-i18next";
import {
    Autocomplete,
    Breadcrumbs,
    Button,
    Card,
    Dropdown, Grid,
    Menu,
    MenuButton,
    Typography
} from "@mui/joy";
import {Link} from "react-router-dom";
import {get} from "../../api/api";
import {Add, FilterList} from "@mui/icons-material";

export const VEvents = (): React.ReactElement => {
    const { t } = useTranslation();

    const [events, setEvents] = useState([
        {
            id: 0,
            logo: 'https://saffronweddingstyle.com/wp-content/uploads/2023/08/corporate-event-planning.jpg',
            name: 'name',
            start_date: '2025-11-29T18:04:31.465Z',
            end_date: '2025-11-29T18:04:31.465Z',
            subCategories: [
                {
                    id: 0,
                    name: "test"
                }
            ],
            localisation: {
                id: 0,
                name: "string"
            }
        },
        {
            id: 0,
            name: 'name',
            logo: 'https://saffronweddingstyle.com/wp-content/uploads/2023/08/corporate-event-planning.jpg',
            start_date: '2024-11-29T18:04:31.465Z',
            end_date: '2024-11-29T18:04:31.465Z',
            subCategories: [
                {
                    id: 2,
                    name: "test"
                }
            ],
            localisation: {
                id: 2,
                name: "string"
            }
        }
    ])

    const [filteredEvents, setFilteredEvents] = useState([
        {
            id: 0,
            name: 'name',
            logo: 'https://saffronweddingstyle.com/wp-content/uploads/2023/08/corporate-event-planning.jpg',
            start_date: '2025-11-29T18:04:31.465Z',
            end_date: '2025-11-29T18:04:31.465Z',
            subCategories: [
                {
                    id: 2,
                    name: "test"
                }
            ],
            localisation: {
                id: 2,
                name: "string"
            }
        },
        {
            id: 0,
            name: 'name',
            logo: 'https://saffronweddingstyle.com/wp-content/uploads/2023/08/corporate-event-planning.jpg',
            start_date: '2024-11-29T18:04:31.465Z',
            end_date: '2024-11-29T18:04:31.465Z',
            subCategories: [
                {
                    id: 2,
                    name: "test"
                }
            ],
            localisation: {
                id: 2,
                name: "string"
            }
        }
    ])

    const [categories, setCategories] = useState<any[]>([
        {
            "subCategories": [
                {
                    "id": 2,
                    "name": "string"
                }
            ],
            "id": 0,
            "name": "string"
        }
    ])
    const [subCategories, setSubCategories] = useState<any[]>([
        {
            category: {
                id: 0,
                name: "string"
            },
            slug: "string",
            id: 2,
            name: "string"
        }
    ])
    const [subCategoriesFiltered, setSubCategoriesFiltered] = useState<any[]>([
        {
            category: {
                id: 0,
                name: "string"
            },
            slug: "string",
            id: 2,
            name: "string"
        }
    ])
    const [localisation, setLocalisation] = useState<any[]>([
        {
            id: 0,
            name: "string"
        }
    ])

    const [filterCategories, setFilterCategories] = useState<number | null>(0)
    const [filterSubCategories, setFilterSubCategories] = useState<number | null>(0)
    const [filterLocalisation, setFilterLocalisation] = useState<number | null>(0)

    useEffect(() => {
        document.title = `EevenPRO - ${t('Liste des évènements')}`
        void get({url: 'events'})
            .then(res => {
                setEvents(res.data)
//                setFilteredEvents(res.data)
            })
            .catch(err => console.log(err))

        void get({url: 'categories'})
            .then(res => setCategories(res.data))
            .catch(err => console.log(err))

        void get({url: 'sub-categories'})
            .then(res => {
                setSubCategories(res.data)
                setSubCategoriesFiltered(res.data)
            })
            .catch(err => console.log(err))

        void get({url: 'localisations'})
            .then(res => setLocalisation(res.data))
            .catch(err => console.log(err))
    }, [t]);

    useEffect(() => {
        setSubCategoriesFiltered(subCategories)

        if (filterCategories) {
            setSubCategoriesFiltered(
                subCategories.filter((subCategories) => subCategories.category.id === filterCategories)
            )
        }
    }, [filterCategories, subCategories])

    useEffect(() => {
        setFilteredEvents(events);

        if (filterSubCategories) {
            setFilteredEvents(filteredEvents.filter((event) => event.subCategories.find((c: any) => c.id === filterSubCategories)))
        }

        if (filterLocalisation) {
            setFilteredEvents(filteredEvents.filter((event) => event.localisation.id === filterLocalisation))
        }
    }, [filterSubCategories, filterLocalisation, events, filteredEvents]);

    return (
        <CLayout>
            <section id={'VEvents'} className={'min-h-[800px]'}>
                <Breadcrumbs separator={'>'}>
                    <Link to={'/'}>{t('EevenPRO')}</Link>
                    <Typography>{t('Liste des évènements')}</Typography>
                </Breadcrumbs>

                <Card className={'rounded-none my-4'} variant={'soft'}>
                    <div className={'flex gap-4 justify-end w-full'}>
                        <Dropdown>
                            <MenuButton variant={'outlined'}>
                                <FilterList />
                            </MenuButton>

                            <Menu className={'w-[500px] !p-4 gap-4'}>
                                <Typography>{t('Filtres')}</Typography>

                                <div className={'flex justify-between gap-4'}>
                                    <Typography>{t('Catégories')}</Typography>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            setFilterCategories(newValue)
                                        }}
                                        className={'w-[70%]'}
                                        options={categories}
                                        getOptionLabel={(option: any) => option.name || ''}
                                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                    />
                                </div>

                                <div className={'flex justify-between gap-4'}>
                                    <Typography>{t('Sous-catégories')}</Typography>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            setFilterSubCategories(newValue?.id)
                                        }}
                                        className={'w-[70%]'}
                                        options={subCategoriesFiltered}
                                        getOptionLabel={(option: any) => option.name || ''}
                                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                    />
                                </div>

                                <div className={'flex justify-between gap-4'}>
                                    <Typography>{t('Localisations')}</Typography>
                                    <Autocomplete
                                        onChange={(event, newValue) => {
                                            setFilterLocalisation(newValue?.id)
                                        }}
                                        className={'w-[70%]'}
                                        options={localisation}
                                        getOptionLabel={(option: any) => option.name || ''}
                                        isOptionEqualToValue={(option: any, value: any) => option.id === value.id}
                                    />
                                </div>
                            </Menu>
                        </Dropdown>

                        <Button variant={'outlined'} onClick={() => window.location.href = '/events/create'}>
                            {t('Ajouter un évènement')} <Add />
                        </Button>
                    </div>
                </Card>

                <Typography level={'h4'} className={'!mb-2'}>Évenements prochains</Typography>
                <Grid spacing={2} container>
                    {
                        filteredEvents.map((event, key) => (
                            <>
                                {(new Date(event.start_date) > new Date() && new Date(event.end_date) > new Date()) && (
                                    <Grid key={key} xs={12}>
                                        <Card key={key} className={'hover:drop-shadow-lg hover:cursor-pointer'} onClick={() => window.location.href = `/events/show?id=${event.id}`}>
                                            <Grid container spacing={2}>
                                                <Grid xs={'auto'}>
                                                    <div className={'w-[100px] h-[75px]'} style={{
                                                        backgroundImage: `url(${event.logo})`,
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'contain',
                                                    }} />
                                                </Grid>

                                                <Grid spacing={0} container xs={9}>
                                                    <Grid xs={12}>
                                                        <Typography level={'h4'}>
                                                            {event.name}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid xs={12}>
                                                        {new Date(event.start_date).toLocaleString('fr-FR')} - {new Date(event.end_date).toLocaleString('fr-FR')}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                )}
                            </>
                        ))
                    }
                </Grid>

                <Typography level={'h4'} className={'!mt-4 !mb-2'}>Évenements passés</Typography>
                <Grid spacing={2} container>
                    {
                        filteredEvents.map((event, key) => (
                            <>
                                {new Date(event.end_date) < new Date() && (
                                    <Grid key={key} xs={12}>
                                        <Card key={key} className={'hover:drop-shadow-lg hover:cursor-pointer'} onClick={() => window.location.href = `/events/show?id=${event.id}`}>
                                            <Grid container spacing={2}>
                                                <Grid xs={'auto'}>
                                                    <div className={'w-[100px] h-[75px]'} style={{
                                                        backgroundImage: `url(${event.logo})`,
                                                        backgroundRepeat: 'no-repeat',
                                                        backgroundSize: 'contain',
                                                    }} />
                                                </Grid>

                                                <Grid spacing={0} container xs={9}>
                                                    <Grid xs={12}>
                                                        <Typography level={'h4'}>
                                                            {event.name}
                                                        </Typography>
                                                    </Grid>

                                                    <Grid xs={12}>
                                                        {new Date(event.start_date).toLocaleString('fr-FR')} - {new Date(event.end_date).toLocaleString('fr-FR')}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Card>
                                    </Grid>
                                )}
                            </>
                        ))
                    }
                </Grid>
            </section>
        </CLayout>
    )
}
